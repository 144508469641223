.restaurant_list_wrapper {
    .restaurant_list_header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 10px 0 31px 0;
        padding-right: 90px;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 129.5%;
        color: #212121;
    }

    .show_more_button {
        width: 100%;
        margin: 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            border: 1px solid #ED1C24;
            border-radius: 8px;
            font-weight: 400;
            font-size: 18px;
            line-height: 100%;
            text-align: center;
            color: #ED3237;
            background: transparent;
            padding: 10px 40px;
            margin: 0 auto;

        }
    }
}