.dashboard_wrapper {
  background: #FFFFFF;
  box-shadow: 0 1px 3px rgba(3, 0, 71, 0.09);
  border-radius: 8px;
  min-width: 235px;
  height: fit-content;
  position: relative;
  margin-bottom: 50px;
  box-sizing: border-box;

  @media screen and (max-width: 650px) {
    width: 95%;

  }

  .dashboard_header {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #000000;
    margin-left: 30px;
  }

  ul {
    margin-top: 30px;
    width: 100%;
    padding-left: 0;

    li {
      width: 100%;
      padding-left: 20px;
      text-align: left;
      cursor: pointer;
      margin-bottom: 10px;

      @media screen and (max-width: 650px) {
        padding: 17px 20px;
        background: #FCFCFC;
        border-bottom: 1px solid #E6E2E2;
        border-radius: 4px;

      }

      img {
        margin-right: 10px;
      }
    }

    .active {
      border-left: 4px solid #F58220;
      color: #F58220;
    }
  }
}

.settings_wrapper {
  background: #FFFFFF;
  margin-left: 30px;
  box-shadow: 0 1px 3px rgba(3, 0, 71, 0.09);
  border-radius: 8px;
  max-width: 777px;
  width: 100%;
  height: auto;

  @media screen and (max-width: 650px) {
    box-shadow: none;
    margin-left: 0;

  }

  .change_password_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border: 1px solid rgba(102, 102, 102, 0.25);
    box-shadow: 0 1px 5px 5px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 9999;

    @media (max-width: 650px) {
      width: 100%;

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    div {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      align-items: center;

      input {
        width: 335px;
        height: 44px;
        background: #FFFFFF;
        border: 1px solid #D9DCDF;
        border-radius: 4px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        margin: 0 auto;
        padding: 0 10px;
        outline: none;
      }

      span {
        width: 180px;
      }
    }

    button {
      margin-top: 20px;
      width: 192px;
      height: 56px;
      background: #F58220;
      border-radius: 8px;
      border: none;
      outline: none;
      opacity: 1;
      color: #fff;
    }

  }

  .settings_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    @media (max-width: 650px) {
      justify-content: center;

      .change_pen {
        display: none;
      }
    }

    .avatar_wrapper {
      position: relative;


      .image-upload > input {
        visibility: hidden;
        width: 0;
        height: 0;
        outline: none;
      }

      .avatar {
        border-radius: 8px;
        width: 181px;
        height: 181px;
        display: block;
      }

      .change_avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        border: 1px solid #000000;
        border-radius: 50%;
        position: absolute;
        right: -30px;
        bottom: -10px;
        background-color: #fff;
      }

    }
  }

  .setting_inputs_area {
    display: flex;
    flex-wrap: wrap;
    margin-top: 23px;
    align-items: center;
    justify-self: flex-start;

    @media screen and (max-width: 650px) {
      width: 100%;
      justify-content: center;
      margin-bottom: 20px;
      flex-direction: column;

      button {
        margin-left: 0 !important;

      }
    }

    .input_div {
      width: 100%;
      max-width: 335px;
      height: 40px;
      border-radius: 4px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      margin: 0 auto;
      color: #000;
      background: #FFFFFF;
      border: 1px solid #D9DCDF;
      padding: 10px;


    }

    div {
      display: flex;
      flex-direction: column;
      margin: 0 10px;
      margin-bottom: 28px;
      width: 100%;
      max-width: 350px;

      label {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: #212121;
        margin-bottom: 14px;
      }

      input {
        max-width: 335px;
        width: 100%;
        height: 44px;
        background: #FFFFFF;
        border: 1px solid #D9DCDF;
        border-radius: 4px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin: 0 auto;
        color: #000;
        outline: none;

      }

      input[type=number]::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

    }

    button {
      width: 192px;
      height: 56px;
      background: #F58220;
      opacity: 0.5;
      border-radius: 8px;
      margin-left: 150px;
      border: none;
      outline: none;
      color: #fff;
    }

    .change_password {
      margin-left: 0;
    }

    .active {
      opacity: 1;
    }
  }
}

.addresses_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 20px;
  width: 100%;

  @media screen and (max-width: 650px) {
    padding-top: 0;
    margin-left: 0;
  }

  .change_and_add_modal {
    z-index: 999;
    background: #FFFFFF;
    /* Shadow/small - Card */
    box-shadow: 0 1px 3px rgba(3, 0, 71, 0.09);
    border-radius: 8px;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .addresses_section {
      padding: 15px 30px;
      padding-top: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .address_header {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 130%;
      color: #212121;
      margin: 10px 0;
      width: 100%;
      ~ .getFoodButton div > span {
        transform: translate(80%, -40%) !important;
      }
    }

    .getFoodButton {
      margin-top: 10px;
    }

    .modal_card_header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #E7EAED;
      margin-bottom: 10px;
      width: 100%;
      padding: 15px 30px;

      img[alt=close] {
        cursor: pointer;
      }

      span {
        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 130%;
        color: #212121;
      }
    }

    @media (max-width: 650px) {
      width: 100%;
      box-sizing: border-box;

      .getFoodButton {
        width: 100%;
      }

      .vehicleInformation {
        width: 100%;
      }

      .input_wrapper {
        width: 100%;
      }

      input {
        width: 100% !important;
      }
    }

    input {
      width: 400px;
      height: 44px;
      background: #FFFFFF;
      border: 1px solid #D9DCDF;
      border-radius: 4px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      margin: 0 auto;
      color: #000;
      outline: none;
    }

    button {
      margin-top: 30px;
      width: 178px;
      height: 56px;
      border: 1px solid #F58220;
      border-radius: 6px;
      font-weight: 600;
      font-size: 18px;
      line-height: 16px;
      text-align: center;
      color: #F58220;
    }

    .disable {
      opacity: 0.5;
    }

    .getFoodButton {
      position: relative;
      margin-left: 0;
      z-index: 9999;

      span {
        position: absolute;
        right: 12px;
        z-index: 9999;
        top: 5px;
        background: #F5F5F5;
      }

    }

    .input_wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;

      label {
        text-align: left;
      }

    }
  }

  .address_lists {
    width: 100%;

    .addresses_header {
      margin-bottom: 50px !important;
    }

    .addresses_item {
      background: #FFFFFF;
      box-shadow: 0 1px 3px rgba(3, 0, 71, 0.09);
      border-radius: 8px;
      position: relative;
      margin-bottom: 20px;
      padding: 30px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      &:not(:first-child) {
        cursor: pointer;
        transition: .1s ease-in-out;
        &:hover {
          box-shadow: 0 1px 3px rgba(3, 0, 71, 0.19);
          transform: scale(1.01);
        }
      }

      img {
        cursor: pointer;
        transition: .1s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }
      }

      div {
        font-size: 15px;
        font-weight: 800;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 5px;

        &:nth-child(1) {
          width: 150px;
        }

        &:nth-child(2) {
          width: 200px;
        }

        &:nth-child(3) {
          width: 150px;
        }

        &:nth-child(4) {
          width: 80px;
        }

        &:nth-child(5) {
          width: 150px;
        }

        &:nth-child(6) {
          width: 100px;
        }
      }


      .img {
        width: 100px;
      }

      @media screen and (max-width: 650px) {
        width: 100%;
        padding: 30px 5px;

      }
    }
  }


  .add_new_address {
    position: relative;
    width: 178px;
    height: 56px;
    border: 1px solid #F58220;
    border-radius: 6px;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    color: #F58220;
    // right: 50px;
    background: #fff;
    margin-bottom: 20px;

    @media (max-width: 650px) {
      left: 10px;
    }
  }
}

.account_payment_wrapper {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 650px) {
    margin-left: 0;
    justify-content: center;
    align-items: center;

    .add_new {
      left: 10px;
      right: unset;
    }

    li {
      margin-left: 5px;
    }
  }

  .add_new {
    background: transparent;
    width: 178px;
    height: 56px;
    border: 1px solid #F58220;
    border-radius: 6px;
    color: #F58220;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    // position: absolute;
    // right: 50px;
  }

  ul {
    margin-top: 20px;
    width: 100%;

    li {
      position: relative;
      padding-right: 100px;
      padding-left: 20px;
      cursor: pointer;
      max-width: 931px;
      width: 100%;
      height: 56px;
      background: #FFFFFF;
      box-shadow: 0 1px 4px rgba(3, 0, 71, 0.19);
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: #2B3445;
      margin-bottom: 20px;

      .accaunt_payment_icon {
        position: absolute;
        right: 3px;
        top: 50%;
        cursor: pointer;
        transform: translate(-50%, -50%);
      }

      .card_icon {
        width: 43px;
        height: 28px;
        margin-right: 5px;
      }

      .card_type {
        text-transform: capitalize;
      }


    }


  }

  .mobile_modal_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 343px;
    width: 100%;
    margin: 0 auto;

    .modal_card_body {
      width: 100%;
    }

    .card_number_area {
      display: flex;
      flex-direction: column;
      max-width: 343px;
      width: 100%;
      height: 64px;
      background: #FFFFFF;
      border: 1px solid #667483;
      position: relative;
      padding: 8px 16px;
      border-radius: 8px;
      margin-bottom: 20px;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #667483;
      }

      img {
        position: absolute;
        right: 24px;
        top: 20px;
        width: 24px;
        height: 24px;
      }

      input {
        width: 90%;
        height: 20px;
        border: none;
        outline: none;
      }


    }

    .div_wrapper {
      display: flex;
      max-width: 343px;
      width: 100%;
      justify-content: space-between;
    }

    .date_desc {
      max-width: 343px !important;
      width: 100% !important;
    }

    .date {
      width: 260px !important;
      margin-right: 10px;
    }

    .button_wrapper {
      display: flex;
      max-width: 343px;
      width: 100%;
      justify-content: space-around;
      margin-top: 20px;
      margin-bottom: 0;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 192px;
        height: 56px;
        background: #F58220;
        border-radius: 8px;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;
        border: none;
        outline: none;
        margin-left: 27px;
      }
    }

    .cvv {
      width: 184px !important;
    }

    input[type=number]::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

  }


  .modal_card {
    z-index: 9999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 518px;
    height: auto;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    .modal_card_header {
      display: flex;
      justify-content: space-between;
      padding: 26px;
      border-bottom: 1px solid #E7EAED;
      margin-bottom: 25px;
      width: 100%;

      span {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 130%;
        color: #212121;
      }
    }

    .card_heading {
      position: relative;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    .modal_card_body {
      .card_number_area {
        display: flex;
        flex-direction: column;
        width: 460px;
        height: 64px;
        background: #FFFFFF;
        border: 1px solid #667483;
        position: relative;
        padding: 8px 16px;
        border-radius: 8px;
        margin-bottom: 20px;

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #667483;
        }

        img {
          position: absolute;
          right: 24px;
          top: 20px;
          width: 24px;
          height: 24px;
        }

        input {
          width: 90%;
          height: 20px;
          border: none;
          outline: none;
        }


      }

      .div_wrapper {
        display: flex;
        width: 460px;
        justify-content: space-between;
      }

      .date {
        width: 260px !important;
      }

      .cvv {
        width: 184px !important;
      }

      input[type=number]::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .date_desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      width: 100%;
      text-align: center;
      color: #667483;
      margin-bottom: 20px;
    }

    button {
      width: 192px;
      height: 56px;
      background: #F58220;
      border-radius: 8px;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      color: #FFFFFF;
      border: none;
      outline: none;
      margin-left: 27px;
    }
  }

  .modal_card_footer {
    padding: 0 26px;
    margin-top: 40px;

    .footer_radio {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-weight: 700;
        font-size: 18px;
        color: #17212B;

        input {
          background: #FFFFFF;
          border: 1px solid #667483;
          width: 20px;
          height: 20px;
        }
      }
    }

    .footer_desc {
      width: 265px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #667483;
      margin-bottom: 10px;
    }
  }
}

.my_preferences_wrapper {
  width: 90%;
  min-height: 300px;
  box-shadow: 0 1px 3px rgba(3, 0, 71, 0.09);
  border-radius: 8px;
  position: relative;
  margin-left: 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;

  .my_preferences_header {
    font-weight: 600;
    font-size: 22px;
    line-height: 129.5%;
    color: #212121;

  }

  .button_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 192px;
      bottom: 34px;
      right: 34px;
      height: 56px;
      background: #F58220;
      opacity: 0.5;
      border-radius: 8px;
      border: none;
      outline: none;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      color: #fff;
    }
  }

  @media screen and (max-width: 650px) {
    top: 5px;
    right: 5px;
  }

  .change_pen {
    position: absolute;
    top: 34px;
    right: 34px;
    cursor: pointer;

    @media screen and (max-width: 650px) {
      top: 5px;
      right: 5px;
    }
  }


  .active_button {
    opacity: 1 !important;
    cursor: pointer;
  }

  .preferences_item {
    display: flex;
    flex-wrap: wrap;

    .preferences_item {
      cursor: pointer;
      background: #E8E8E8;
      border-radius: 30px;
      padding: 10px 20px;
      width: fit-content;
      height: fit-content;
      margin: 5px;
    }

    .active {
      background: #F58220;
    }
  }

}

.favorites_wrapper {
  position: relative;
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 30px;
  justify-content: flex-start;
  margin-bottom: 30px;
  width: 100%;
  max-width: 800px;

  .favorites_products {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;

    @media (max-width: 650px) {
      justify-content: space-between;
    }

    .item_wrapper {
      margin: 10px;
    }

    .favorites_header {
      width: 100%;
      margin: 0 16px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #292E34;
      border-bottom: 2px solid #BCBCBC;

    }
  }

  .no_favorete_products {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .adItem {
    width: 240px;

    img {
      position: absolute;
    }
  }

  .item {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 240px;
    height: 290px;
    overflow: hidden;
    position: relative;

    .heart {
      position: absolute;
      z-index: 1;
      right: 10px;
      top: 10px;
      cursor: pointer;
      transition: .2s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }

    > .itemImage {
      height: 144px;
      width: 240px;
      object-fit: cover;
      border-radius: 10px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    > div {
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        margin: 0 !important;
        height: 46px;
      }

      > div:not(.stars) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price {
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          color: #ED1C24;
          display: flex;
          align-items: center;
        }

        .sale {
          line-height: 18px;
          font-weight: 400;
          text-decoration-line: line-through;
          color: #ADADAD;
          margin-left: 10px;
        }

        button {
          padding: 8px 25px;
          background: #F58220;
          border-radius: 2px;
          border: none;
          color: #FFFFFF;
          align-items: center;
          display: flex;

          img {
            margin-right: 5px;
          }
        }
      }

      span {
        color: #ADADAD;
        font-size: 12px;
      }

      p {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        color: #253D4E;
      }
    }
  }
}

@media (max-width: 650px) {
  .favorites_wrapper {
    margin-left: 0;

    .item_wrapper {
      margin: 10px;
    }
  }
}

.my_coupon_wrapper {
  // position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  button {
    background: transparent;
    width: 178px;
    height: 56px;
    border: 1px solid #F58220;
    border-radius: 6px;
    color: #F58220;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    // right: 50px;
    // top: 170px;
  }

  .no_favorete_products {
    // position: absolute;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    // transform: translate(50%, -10%);
  }

  .coupon_items {
    display: flex;
    flex-wrap: wrap;
    width: 72%;
    justify-content: space-between;

    .coupon_items_wrapper {
      width: 343px;
      margin-left: 20px;
      margin-bottom: 30px;

      p {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #292E34;
      }

      h1 {
        width: 80%;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #292E34;
      }

      .coupon_desc {
        background: #FFFFFF;
        width: 343px;
        // height: 162px;
        border: 1px solid #ECECEC;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 10px 15px 15px;

        img {
          width: 70px;
          height: 38px;
          margin: 10px auto;
        }

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .coupon_percent {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #E85D42;
          margin-bottom: 10px;
        }
      }
    }
  }

  .modal {
    position: absolute;
    width: 789px;
    height: 377px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 850px) {
      width: 100%;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 22px 26px;
      border-bottom: 1px solid #E7EAED;
      width: 100%;

      span {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 130%;
        color: #212121;

      }

      img {
        width: 25px;
        height: 25px;
      }

    }

    .not_valid {
      margin-left: 26px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #FF4D54;

    }

    input {
      width: 90%;
      height: 64px;
      background: #EEF2F7;
      border-radius: 8px;
      margin: 0 auto;
      margin-top: 59px;
      border: none;
      outline: none;

    }

    button {
      position: relative;
      left: 0;
      width: 192px;
      height: 56px;
      background: #F58220;
      opacity: 0.5;
      border-radius: 8px;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      color: #FFFFFF;
      margin-left: 26px;
      margin-top: 71px;
    }

    .active {
      opacity: 1;
    }
  }
}

.faq_wrapper {
  width: 100%;
  height: auto;
  margin-left: 20px;

  .item_wrapper {
    .item_header {
      margin-bottom: 30px;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 100%;
      color: #212121;
      width: 100%;
    }

    .item {
      border: 1px solid #E7EAED;
      border-radius: 4px;
      max-width: 852px;
      width: 100%;
      height: auto;
      text-overflow: ellipsis;
      padding: 18px 28px;
      box-sizing: border-box;
      margin-bottom: 10px;
      position: relative;

      h1 {
        font-weight: 400;
        font-size: 18px;
        line-height: 100%;
        color: #212121;
      }

      h2 {
        font-weight: 400;
        font-size: 16px;
        line-height: 170%;
        color: #212121;
        display: none;
        border-left: 1px solid #898989;
        padding-left: 5px;

      }

      span {
        font-weight: 800;
        position: absolute;
        right: 10px;
        font-size: 22px;
        top: 10px;
        text-align: center;
        cursor: pointer;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background: #F9FAFA;
      }
    }
  }
}

.security_wrapper {
  width: 100%;
  margin-left: 20px;

  .security_item {
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 170%;
      color: #212121;
      margin-bottom: 5px;
      margin-top: 5px;

    }

    h2 {
      font-weight: 400;
      font-size: 18px;
      line-height: 170%;
      color: #212121;
      margin-bottom: 17px;

    }
  }
}

.terms_wrapper {
  width: 100%;
  margin-left: 20px;

  .terms_item {
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 170%;
      color: #212121;
      margin-bottom: 5px;
      margin-top: 5px;

    }


    div {

      font-weight: 400;
      font-size: 18px;
      line-height: 170%;
      color: #212121;
      margin-bottom: 17px;
    }
  }

}

.order_history_wrapper {
  width: 100%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;

  nav {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0;

    div {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      color: #000000;
    }
  }

  .item {
    width: 98%;
    margin: 10px 0;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 5px;
  }

  .order_history_item {
    max-width: 924px;
    width: 100%;
    height: 72px;
    // margin-left: 30px;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;

    .order_history_item_id {
      width: 120px;
    }

    .order_history_item_img {
      width: 24px;
      height: 24px;
    }

    .order_history_item_date {
      width: 200px;
    }

    .order_history_item_price {
      width: 200px;
    }

    .order_history_item_feedback {
      text-decoration: underline;
      font-weight: 400;
    }

    span {
      min-width: 120px;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 150%;
      color: #2B3445;
      text-align: left;
    }

    .order_history_item_status {
      min-width: 120px;
      margin-right: 60px;
      padding: 10px;
      background: #F3F6F9;
      border-radius: 4px;
      text-align: center;
      color: #fff;
    }
  }

  .more_desc_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 30px;

    .more_wrapper {
      max-width: 300px;
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 10px 0;

      img {
        width: 91px;
        height: 83px;
        border-radius: 5px;
      }

      div {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        .product_name {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          display: flex;
          align-items: center;
          color: #000000;
        }

        .product_price {
          font-weight: 400;
          font-size: 20px;
          letter-spacing: 0.02em;
          color: #1E1D1D;
        }
      }
    }

    .prices_wrapper {
      margin-left: 100px;
      width: 300px;

      div {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

  }

  .buttons_wrapper {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      width: 192px;
      height: 56px;
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      color: #F58220;
      border: 1px solid #F58220;
      border-radius: 8px;
      margin: 20px;

    }
  }

  .show_more {
    width: 235px;
    height: 52px;
    border: 1px solid #F58220;
    border-radius: 6px;
    background-color: transparent;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.005em;
    text-align: center;
    color: #F58220;
    margin: 10px auto;
  }
}

@media (max-width: 1200px) {
  .order_history_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0;

    .order_history_item {
      max-width: 350px;
      width: 100%;
      padding: 0 10px;
      margin-left: 0;

      .order_history_item_id {
        width: 60px;
      }

      .order_history_item_status_header {
        font-size: 12px;
      }

      .order_history_item_date,
      .order_history_item_price {
        display: none !important;
      }
    }

    .order_history_header {
      display: none;
    }

    .buttons_wrapper {
      width: 100%;
      justify-content: center;
    }

    .more_desc {
      max-width: 350px;
      width: 100%;

      .more_desc_wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 0;

        .prices_wrapper {
          margin-left: 0;
        }
      }
    }
  }
}

.no_order_history {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.feedback_wrapper {
  position: absolute;
  z-index: 9999;
  width: calc(100% - 20px);
  max-width: 780px;
  height: 516px;
  background: #FFFFFF;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
  }

  .feedback_header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #E7EAED;
    justify-content: space-between;

    span {
      margin: 22px 0 22px 46px;
      font-weight: 700;
      font-size: 22px;
      color: #212121;

    }

    img {
      cursor: pointer;
      width: 30px;
      height: 30px;
      margin-top: 22px;
      margin-right: 16px;
    }
  }

  .feedback_body {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    margin-left: 46px;

    @media (max-width: 450px) {
      margin-left: 10px;
    }

    .stars_wrapper {
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      .feedback_types_name {
        margin-right: 10px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: #212121;
        min-width: 80px;
      }

      .stars {
        margin-right: 10px;

        img {
          cursor: pointer;

          @media (max-width: 450px) {
            width: 15px;;
          }
        }
      }

    }
  }

  .feedback_input {
    display: flex;
    flex-direction: column;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: #212121;
    }

    textarea {
      max-width: 730px;
      width: calc(100% - 10px);
      height: 81px;
      background: #FFFFFF;
      border: 1px solid #D9DCDF;
      border-radius: 4px;
      outline: none;
      resize: none;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      padding: 10px;
    }
  }

  button {
    width: 192px;
    height: 56px;
    margin: 10px auto;
    margin-left: 39%;
    background: #F58220;
    opacity: 0.5;
    border-radius: 8px;
    outline: none;
    border: none;

    @media (max-width: 450px) {
      margin: 10px auto;
    }
  }
}