.pop_up_wrapper {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  width: clamp(300px, 95%, 1024px);
  height: auto;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999;
  color: aliceblue;
  padding: 3%;
  box-sizing: border-box;
  opacity: 1 !important;
  overflow: hidden;

  .pop_up_error {
    width: 95%;
    margin: 10px auto;
    display: flex;
    height: 135px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .pop_up_error_body {
      width: 100%;
      height: 80px;
      font-weight: 600;
      font-size: 18px;
      line-height: 160%;
      color: #000000;
      background: rgba(245, 130, 32, 0.27);
      border-radius: 4px;
      padding: 10px;
    }

    .pop_up_error_footer {
      width: 100%;
      display: flex;
      height: 44px;
      justify-content: space-between;

      button {
        width: 166px;
        height: 44px;
        background: #F58220;
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 160%;
        color: #FFFFFF;
        border: none;
        outline: none;

      }
    }
  }

  .pop_up_is_close {
    width: 100%;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      margin: 10px auto;
      display: flex;
      height: 40px;
      align-items: center;
      background: rgba(245, 130, 32, 0.27);
      border-radius: 4px;
      padding: 0 10px;
      line-height: 160%;
      font-weight: 400;
      font-size: 19px;

    }

    button {
      width: 84px;
      height: 32px;
      background: #FADFD9;
      border-radius: 16px;
      border: none;
      outline: none;
    }
  }

  .pop_up_is_cart_clear {
    width: 95%;
    margin: 10px auto;
    display: flex;
    height: 51px;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    color: #000000;
    background: #FAFAFA;
    border-radius: 4px;
    padding: 0 10px;

    div {
      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #47f5983a;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;

    }
  }

  .pop_up_header {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E7EAED;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 130%;
    color: #212121;

    img {
      cursor: pointer;
    }
  }

  .pop_up_body {
    display: flex;
    width: 100%;
    height: 90%;

    .pop_up_picture {
      width: 40%;
      height: 356px;
      border-radius: 10px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .pop_up_information {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      width: 60%;

      .pop_up_info_header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pop_up_product_name {
          font-weight: 400;
          font-size: 28px;
          line-height: 35px;
          color: #212121;
        }

        .pop_up_price {
          font-weight: 700;
          font-size: 28px;
          line-height: 120%;
          color: #ED3237;
        }

        img {
          width: 25px;
          height: 25px;
        }
      }

      .pop_up_desc {
        width: 450px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #898989;
      }

      .pop_up_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90px;

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #212121;
          cursor: pointer;
          user-select: none;
        }

        .pop_up_count {
          width: 36px;
          height: 36px;
          border: 1px solid #E7EAED;
          border-radius: 4px;
          text-align: center;
          padding-top: 5px;
          box-sizing: border-box;
        }

      }

      .pop_up_selects_area {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        height: 223px;
        margin-bottom: 20px;
        overflow: auto;

        .area_header {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #666666;
          margin-bottom: 8px;
        }

        .pop_up_select_area_left {
          width: 85%;
          display: flex;
          max-height: 100%;
          flex-direction: column;
          gap: 7px;
          margin-top: 8px;
          > div {
            max-height: 100%;
          }
          .ant-collapse-content {
            max-height: 100%;
          }
          .ant-collapse-content-box {
            padding: 12px;
          }

          .ant-collapse {
            min-height: 46px;
            > div {
              max-height: 175px;
              overflow: hidden;
            }
          }

          >div {
            max-height: 100%;
            overflow: hidden;
            border-radius: 12px;
            border: 1px solid rgba(102, 102, 102, 0.35);

            >div {
              border: 0;
              height: 90%;

              >div:nth-of-type(2) {
                max-height: 151px;
                overflow: auto;
                padding-bottom: 16px;
              }
            }
          }

          .souces_area {
            padding: 11px 24px;
            overflow-y: auto;
            height: 200px;
            width: 100%;
            border: 1px solid rgba(102, 102, 102, 0.35);
            border-radius: 12px;
          }
        }

        .pop_up_select_area_right {
          width: 85%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .pop_up_select_did_cup {
            max-height: 165px;

            .did_cup {
              padding: 3px 4px;
              border: 1px solid rgba(102, 102, 102, 0.35);
              border-radius: 12px;
              width: 100%;
              height: 97px;
              overflow-y: auto;
              overflow-x: hidden;
            }
          }

          .pop_up_textarea {
            width: 100%;
            display: flex;
            flex-direction: column;

            textarea {
              width: 100%;
              height: 75px;
              border: 1px solid rgba(102, 102, 102, 0.35);
              border-radius: 12px;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #7E7E7E;
              resize: none;
              outline: none;
              padding: 10px;
            }
          }
        }

        .souces_list {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height */
          color: #111111;

          .souces_list_item {
            font-weight: 400;
            font-size: 14px;
            line-height: 185%;
            display: flex;
            align-items: center;
            margin: 4px 5px;
            transition: .1s ease-in-out;

            &:hover {
              transform: scale(1.01);
              opacity: 1;
            }

            input {
              width: 16px;
              height: 16px;
              margin-right: 10px;
            }
          }
        }
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 12px;
      
      .pop_up_picture, .pop_up_information {
        width: 100%;
      }
    }
  }

  .add_button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      width: 300px;
      height: 44px;
      background: #F58220;
      border: 1px solid #FF5722;
      border-radius: 4px;
      outline: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
      color: #fff;

      &:disabled {
        opacity: .5;
      }
    }
  }
}