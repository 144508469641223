.switchersWrapper {
  display: flex;
  max-width: 1300px;
  margin: auto;

  div {
    margin-right: 50px;
    min-width: 340px;
  }

  button {
    height: 60px;
    width: 170px;
    background: #F9F9F9;
    border-radius: 4px;
    border: none;
    font-size: 20px;

    :link {
      text-decoration: none;
    }


    :visited {
      text-decoration: none;
    }


    :hover {
      text-decoration: none;
    }


    :active {
      text-decoration: none;
    }

    &.active {
      border: 1px solid #F58220;
      color: #ED3237;
      font-weight: 700;
      background: white;
    }
  }

  input {
    background: #FFFFFF;
    box-shadow: 0 1px 2px rgba(27, 78, 163, 0.24), 0 2px 4px rgba(41, 121, 255, 0.24);
    border-radius: 4px;
    color: #ED3237;
    border: none;
    height: 60px;
    min-width: 370px;
    font-weight: 700;
    padding: 16px 13px;
  }
}

.title {
  background: #F9F9F9;
  padding: 32px;
  margin: 0 0 30px;

  h3 {
    color: #2E2E2E;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    max-width: 1300px;
    margin: auto;
  }
}

.categories {
  max-width: 1350px;
  display: flex;
  margin: 0 auto;
  // height: 200px;
  margin-bottom: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;

  .category {
    margin-right: 20px;

    .selected {
      border: 4px solid #F58220;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 4px rgba(0, 0, 0, 0.25);
    }

    div {
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25), inset 0 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      height: 125px;
      width: 125px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        object-fit: contain;
      }
    }

    p {
      text-align: center;
      font-size: 18px;
      margin-top: 10px;
    }
  }
}

.subMenu {
  display: flex;
  justify-content: center;

  .searchWrapper {
    position: relative;
    display: flex;

    &:after {
      content: url("../../img/darkSearch.svg");
      position: absolute;
      left: 367px;
      fill: black;
      top: 30%;
    }

    .search {
      outline: none;
      background: #F5F5F5;
      border-radius: 8px;
      width: 400px;
      border: none;
      padding: 12px;
    }
  }


}