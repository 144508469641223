// @import '~antd/dist/antd.css';
$black: #1F1F1F;
$red: #ED3237;
$orange: #F58634;

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

body,
html {
  font-family: 'Source Sans Pro', sans-serif !important;

  button {
    cursor: pointer !important;
  }

}


.rel {
  position: relative;
  background-color: red;
  height: calc(100% + 160px);
}

/**
 * Hover
 */
.hoverable {
  width: 193px;
  cursor: pointer;
  -webkit-transition: 0.2s all;
  -moz-transition: 0.2s all;
  -ms-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;

  &:hover {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
}


// END Hover


::selection {
  background: $red;
}

button:focus {
  outline: none !important;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0 !important;
}

a {
  &:hover {
    text-decoration: none;
  }
}

.ant-input:hover,
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: $orange !important;
}

.ant-input:focus {
  border-color: $orange !important;
}

.ant-input:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(245, 134, 52, 0.2);
  -moz-box-shadow: 0 0 0 2px rgba(245, 134, 52, 0.2);
  box-shadow: 0 0 0 2px rgba(245, 134, 52, 0.2);
}


.ant-checkbox-inner {
  -webkit-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.18);
  -moz-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.18);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.18);
  border: 1px solid #BCC3CC;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $orange;
}

.ant-radio-checked::after {
  border-color: $orange;
}

.ant-radio-inner {
  &::after {
    background-color: $orange;
  }
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
  border-color: $orange;
}

.Uynushka {
  text-align: right;
}

@media screen and (max-width: 767px) {

  .ant-modal,
  .CustomBasketModal,
  .CustomNotificationModal,
  .ShowImg {
    top: 0 !important;
    padding-bottom: 0 !important;
    // margin-bottom: 8px !important;
  }

  .Modal-wrap {
    z-index: 999 !important;
    padding-bottom: 0 !important;
  }

  .ant-modal-body {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}


nav.Nav {
  position: fixed;
  top: 0;
  width: 100%;
  margin: 0 auto;
  //box-shadow: 0 0 10px 2px #0000001f;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 30px;
  z-index: 110 !important;
  //padding-right: 70px;
  align-items: center;
  left: 0;
  display: flex;
  justify-content: space-between;
  // min-width: 1400px;
  top: 0;

  // .nav_wrapper{
  //   display: flex;
  //   justify-content: space-between;
  //   padding: 14px 30px;
  //   min-width: 1400px;
  // }
  .Logo {
    cursor: pointer;
  }

  &.dodo {
    box-shadow: 0 0 3px 3.5px #d8d8d880 !important;
    background-color: #fff !important;

    .SearchFood {
      li {
        input {
          background: #F3F3F3 !important;
        }
      }
    }
  }

  /*================================================
          nav scrolled class
  ================================================*/
  .MobileMenu {
    position: relative;
    width: 23px;
    height: 20px;
    z-index: 3;
    cursor: pointer;

    &.active {
      span {
        background-color: transparent;

        &::after,
        &::before {
          top: 0;
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          background-color: $red;
        }

        &::before {
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -ms-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: rotate(135deg);
        }
      }
    }

    span {
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $orange;
      top: calc(50% - 1px);
      left: 0;
      -webkit-transition: 0.3s all;
      -moz-transition: 0.3s all;
      -ms-transition: 0.3s all;
      -o-transition: 0.3s all;
      transition: 0.3s all;

      &::after,
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $orange;
        left: 0;
        top: 7px;
        -webkit-transition: inherit;
        -moz-transition: inherit;
        -ms-transition: inherit;
        -o-transition: inherit;
        transition: inherit;
      }

      &::before {
        top: -7px;
      }
    }
  }

  &.scrolled {
    background-color: #fff;
    box-shadow: 0 0 3px 3.5px #d8d8d880;

    ul {
      &.SearchFood {
        li {
          position: relative;

          input {
            background: #F3F3F3 !important;
          }
        }
      }
    }
  }

  /*++++++++++*/

  ul {
    &.Logo {
      img {
        cursor: pointer;
        width: 90px;
      }
    }
  }

  .sign_menu {
    .CartIcon {
      width: 50px;
    }
  }

  .Menu {
    color: $black;
    display: flex;
    align-items: center;

    .phone_number {
      color: $black
    }


    li {
      margin-right: 30px;
      cursor: pointer;
      //text-transform: uppercase;
      color: #F54748;
      font-size: 15px;
      font-weight: 600;
      position: relative;
      display: flex;

      button {

        padding: 10px 70px;
        border: none;
        color: white;
        font-weight: 700;
        background: #ED3237;
        border-radius: 6px
      }

      img {
        //height: 30px;
        cursor: pointer;
        margin-right: 10px;
      }

      a {
        color: $black;

        &:hover {
          text-decoration: none;
        }
      }

      &.CartIcon {
        margin-right: 10px;
      }

      &.MenuSignUp {
        color: $red;
      }

      .UserAccountLink::after {
        background-color: unset;
      }

      &.UserAccountLink {
        a {

          text-decoration: none !important;
        }

        .userDropdown {
          width: 154px;
          position: absolute;
          top: 100%;
          // left: 0;
          right: 0;
          padding-top: 5px;
          text-align: center;
          background-color: #fff;
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.1);
          border-radius: 5px;

          ul {
            width: 100%;

            li {
              width: 100%;
              cursor: pointer;
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 10px;
              // margin-left: -40px;
              text-align: center;
              box-sizing: border-box;

              &:hover {
                background-color: #f7f7f7;
              }

              a {
                color: #747474;
              }

              &:last-child {
                a {
                  color: $red;
                  font-size: 16px;
                  line-height: 20px;
                  font-weight: 700;
                  text-decoration: none !important;
                }
              }
            }
          }
        }
      }

      @media screen and (min-width: 650px) {

        &:not(.hoverable):not(.userDropdownLi)::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: $red;
          left: 0;
          bottom: -5px;
          opacity: 0;
          -webkit-transition: 0.3s all;
          -moz-transition: 0.3s all;
          -ms-transition: 0.3s all;
          -o-transition: 0.3s all;
          transition: 0.3s all;
        }

        &:not(.hoverable):not(.userDropdownLi):last-child::after {
          background-color: transparent;
        }


        &:not(.hoverable):not(.userDropdownLi):hover::after {
          opacity: 1;
          bottom: -2px;
        }
      }
    }
  }

  .NotCount {
    position: relative;

    &::after {
      position: absolute;
      content: attr(data-count);
      width: 21px;
      height: 21px;
      background-color: $orange;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      //border: 1px solid #fff;
      font-size: 13px;
      color: #FFFFFF;
      font-weight: 700;
      left: 19px;
      bottom: 16px;
      text-align: center;
    }

    @media screen and (max-width: 1200px) {
      position: absolute;
      right: 22px;
      z-index: 4;

      img {
        width: 26px;
      }

      &::after {
        width: 18px;
        height: 18px;
        font-size: 12px;
        left: 15px;
        bottom: 12px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .Menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 55px;

      li {
        display: inline-block;
        margin-left: 0 !important;
        margin-right: 5px !important;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .SocialUl {
        margin-top: 15px;
        display: flex;

        li {
          margin-left: 10px !important;
          margin-right: 10px !important;

          img {
            height: 40px;
          }
        }
      }
    }

    ul.Logo {
      img {
        width: 70px;
      }
    }
  }
}

.vehicleInformation {
  width: 100%;

  @media (max-width: 1024px) {
    width: 100% !important;
  }

  input {
    padding: 0 34px 0 35px;
  }
  img {
    position: absolute;
    bottom: 20px;
    right: 155px;
    width: 20px;
  }

  &:hover .verifyImg {
    display: none;
  }

  &:hover img.cancelImg {
    display: block;
  }

  img.cancelImg {
    display: none;
    cursor: pointer;
  }

}


footer {
  border-top: 1px solid #EDEDED;

  //margin-top: 160px;
  @media screen and (max-width: 767px) {
    text-align: center;

    ul,
    div {
      justify-content: center;
      align-items: center !important;
    }
  }

  .footer {
    max-width: 1190px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 15px;
    position: relative;
  }

  h4 {
    margin-top: 10px;
    font-size: 24px;
    color: $black;
    letter-spacing: 0;
  }

  h5 {
    font-weight: 600;
    font-size: 14px;
  }

  .Socials {
    ul {
      margin-top: 60px;
      display: flex;
    }

    li {
      margin-right: 20px;

      img {
        width: 42px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 30px;

      h4 {
        margin-top: 0;
      }

      ul {
        margin-top: 15px;
      }
    }
  }

  .GetAround {
    position: relative;

    >ul {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
    }

    li {
      line-height: 14px;
      font-size: 12px;
      color: #7C7C7C;
      cursor: pointer;
      display: inline;

      &:hover {
        text-decoration: underline;
      }

      a {
        font-size: 12px;
        color: #7C7C7C;
      }
    }

    img {
      position: absolute;
      width: 275px;
      left: 90px;
      bottom: -178px;
      z-index: -2;
    }

    @media screen and (max-width: 767px) {
      position: static;
      margin-bottom: 30px;

      img {
        left: auto;
        bottom: 0;
        right: 15px;
      }

      li {
        font-size: 14px;
        margin-bottom: 5px;

        a {
          font-size: 14px;
        }
      }
    }
  }

  .Newsletter {
    position: relative;

    p {
      font-size: 12px;
      color: #7C7C7C;
      margin-bottom: 8px;
    }

    img {
      position: absolute;
      width: 186px;
      left: 180px;
      bottom: -165px;
      z-index: -12;

      @media screen and (min-width: 1370px) {
        left: 280px;
      }

      @media screen and (max-width: 1200px) {
        display: none;
      }
    }

    input {
      max-width: 270px;
      width: 100%;
      padding: 8px 15px;
      font-size: 12px;
      line-height: 15px;
      color: #2E2E2E;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 3px 0 0 3px;
      border: 1px solid #d2d2d2;

      &::placeholder {
        color: #9e9e9e;
      }

      &:focus {
        outline: none;
      }

    }

    button {
      background: $red;
      border-radius: 0 3px 3px 0;
      border: none;
      line-height: 15px;
      color: #ffffff;
      font-weight: 600;
      padding: 10px 13px;
      font-size: 12px;
    }

    &>div {
      display: flex;
    }

    @media screen and (max-width: 767px) {
      p {
        font-size: 14px;
      }
    }
  }

  .Copyright {
    padding: 25px 0;
    background-color: $orange;

    >div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1190px;
      padding-left: 15px;
      padding-right: 15px;
      width: 100%;
      margin: 0 auto;
    }

    p {
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      margin-bottom: 0;
    }

    ul {
      display: flex;
      align-items: center;

      li:not(:first-of-type) {
        margin-left: 40px;
      }

      li {
        a {
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-decoration: underline;
        }
      }
    }

    @media screen and (max-width: 575px) {
      >div {
        display: block;
        text-align: center;

        ul {
          justify-content: center;
        }
      }
    }
  }
}


/*==========================
      header
==========================*/

header {
  height: auto;
  margin-top: 90px !important;
  //height: 480px;
  background-position: right;
  max-width: 1400px;
  background-size: 550px;
  justify-content: center;
  -webkit-background-size: 550px;
  background-repeat: no-repeat;
  left: 0;
  right: 0;
  margin: auto;
  position: relative;

}

.close_branch {
  font-weight: bold;
  position: relative;

  a {
    color: #000000;
    text-decoration: none;
  }

  &:after {
    content: '' !important;
    position: absolute !important;
    left: -20px !important;
    top: 5px !important;
    width: 15px !important;
    height: 15px !important;
    background-color: red !important;
    border-radius: 50% !important;
    opacity: 1 !important;
  }
}

.getFoodType {
  >div {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;

    @media screen and (max-width: 520px) {
      display: block;

      >div {
        display: flex;
        // justify-content: space-around;
        width: 100% !important;

        button {
          font-size: 14px;
          margin: 0 !important;
        }
      }
    }

    >div {
      button {
        border: none;
        font-weight: bold;
        border-radius: 5px;
        background-color: #fdfdfd;

        &.active {
          color: $orange;
        }
      }
    }
  }
}

/*==============================
          address
===============================*/

.HomeSearchComponent {
  // min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1400px;
  width: 100%;
  color: #757575;

  .get_food_type_wrapper {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 1200px) {
    .get_food_type_wrapper {
      flex-direction: column-reverse;
    }
  }

  &.homePageMenus {
    flex-direction: row;
    margin: auto;

    >div:last-child {
      margin-top: 170px;
    }

    img {
      object-fit: contain;
    }
  }

  >div:not(.getFoodType) {
    display: flex;
  }

  span {
    color: #e7792b;
  }

  .active_type {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 1200px) {
      .active_type {
        justify-content: flex-start;
      }

    }

    button {
      color: #fff;
      border-radius: 8px;
      background: rgba(32, 32, 32, 0.27);
    }

    .active {
      color: #fff;
      background: #E7792B;
    }
  }


  button {
    display: flex;
    align-items: center;
    border: none;
    padding: 15px 65px;
    font-weight: 700;
    font-size: 18px;
    border-radius: 8px;
    background-color: transparent;


    @media screen and (max-width: 520px) {
      padding: 10px 24px 10px 24px;
      font-size: 15px;
    }

    &:first-child {
      margin-right: 10px;
    }

    &.active {
      color: #E7792B;
      background: rgba(231, 121, 43, 0.116);
    }
  }

  h2 {
    font-size: 76px;
    max-width: 600px;
    color: $black;
    font-weight: 700;
    line-height: 70px;
    margin: 30px 0 20px;
  }

  p {
    max-width: 500px;
  }

  .getFoodType {
    box-shadow: rgba(231, 121, 43, 0.1) 0 0 7px 7px, rgba(231, 121, 43, 0.1) 0 0 7px 7px, rgba(231, 121, 43, 0.1) 0 0 7px 7px;
    padding: 25px;
    max-width: 850px;
    width: 60%;
    min-width: 370px;

    button img {
      margin-right: 10px;
    }

    >div>div {
      display: flex;
      width: 100%;
    }

    & :first-of-type {
      & :not(:first-child) {
        margin-left: 15px;
      }
    }
  }

  .getFoodButton {
    display: flex !important;
    flex-direction: row;
    margin-top: 30px;
    align-items: center;
    position: relative;
    width: 100%;

    @media (max-width: 1024px) {
      margin-left: 0;
    }

    &.cateringMenu {
      margin-top: 100px;

      p {
        max-width: 300px;
      }
    }

    span {
      position: absolute;
      transform: scale(1.3);
      left: 10px;
    }

    >button {
      width: 199px;
      height: 60px;
      text-align: center;
      //position: absolute;
      //top: 1px;
      //right: 0;
      //height: calc(100% - 1px);
      white-space: nowrap;
      // padding: 20px 40px;
      background: $red;
      border-radius: 8px;
      font-size: 18px;
      color: #FFFFFF;
      font-weight: 600;
      border: none;
      line-height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: #d8232a;
      }

      &:focus {
        outline: none;
      }

      img {

        height: 15px;
        width: 15px;
        margin-right: 5px;
      }
    }

    @media screen and (max-width: 1024px) {
      button {
        width: 119px;
        height: 43px;
      }
    }
  }

  &>div {
    width: 100%;
    margin-top: 40px;
    position: relative;

    @media screen and (min-width: 1024px) {
      margin: 50px;
    }

    span {
      >img {
        width: 24px;
        cursor: pointer;
      }

      &:hover img {
        -webkit-animation: bounce 1s infinite;
        -o-animation: bounce 1s infinite;
        animation: bounce 1s infinite;
      }
    }

    input {
      width: 100%;
      // max-width: 410px;
      padding: 16px 16px 16px 60px;
      background: #F5F5F5;
      border: none;
      border-radius: 8px;
      line-height: 23px;
      font-size: 18px;
      color: #747474;

      &:focus {
        outline: none;
      }
    }


  }

  @media screen and (max-width: 1200px) {
    h2 {
      font-size: 26px;
      line-height: 40px;
    }

    .getFoodType {
      display: block;
      text-align: center;
      padding: 10px;
      margin: 0 auto;
      width: 100%;

      .getFoodTypeSelects {
        display: block;
        margin: auto;
      }
    }

    .getFoodButton {
      margin-left: 0;
      width: 100%;

      button {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 13px;
      }
    }

    >div {
      margin-top: 30px;

      input {
        padding: 10px 0 10px 60px;
        font-size: 13px;
      }


      span {
        left: 10px;

        &:hover img {
          animation: bounce 1s infinite;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    max-width: none;
  }

  @media (max-width: 1200px) {
    .active_type {
      justify-content: center;
    }

  }
}

/*================================
        Modals
================================*/
.Modal-wrap {
  position: fixed;
  width: 100%;
  max-height: 700px;
  overflow: auto;
  padding-bottom: 24px;
  z-index: 120;
  left: 0;
  top: 0;
}

.Modal-close {
  position: absolute;
  right: 35px;
  line-height: 14px;
  top: 35px;
  border: none;
  background-color: transparent;
  font-size: 20px;
  width: 16px;
  height: 16px;
  background-image: url('../../img/ic_close.svg');
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  display: none !important;

  i {
    display: none;
  }
}

/*===================================
        CustomNotificationModal
======================================*/
.CustomNotificationModal {
  max-width: 464px;
  position: absolute;
  right: 55px;
  top: 0;
  background-color: #fff;
  border-radius: 0 0 12px 12px;
  overflow: hidden;

  ul {
    padding-bottom: 25px;

    li {
      padding: 16px 35px 20px;
      border-bottom: 1px solid #EDEDED;
      position: relative;

      img {
        position: absolute;
        right: 35px;
        top: 50%;
        height: 42px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }

      &:last-of-type {
        border: none;
      }
    }
  }

  .NotificationTitle {
    padding: 35px;
    margin-bottom: 0;
    font-size: 18px;
    color: $black;
    line-height: 23px;
    padding-bottom: 30px;
    border-bottom: 1px solid #EDEDED;
  }

  .NotificationTime {
    font-size: 12px;
    color: #747474;
    line-height: 15px;
    margin-bottom: 6px;
    display: inline-block;
  }

  .NotificationDescription {
    font-size: 14px;
    color: #1B1B1B;
    font-weight: 600;
    line-height: 18px;
    position: relative;
    z-index: 4;
    margin: 0;
  }

  .NotificationAccept {
    p {
      span {
        color: $red;
      }
    }
  }

  @media screen and (max-width: 767px) {
    max-width: calc(100vw - 16px) !important;
    position: relative;
    top: 100px;
    margin: 8px auto;
    left: 0;
    margin-bottom: 24px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }

  .Modal-close-x {
    display: none !important;
  }

  .notFound {
    div {
      text-align: center;
      padding: 50px;

      p {
        margin: 1.5rem;
      }
    }
  }
}


/*===================================
        CustomBasketModal
======================================*/
.CustomBasketModal {
  position: absolute;
  right: 55px;
  top: 85px;
  width: 353px !important;
  height: auto !important;
  background: #FFFFFF;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.47);
  border-radius: 10px;


  ul {
    padding-left: 18px !important;
    max-height: 300px;
    overflow-y: auto;

    .modal_name {
      font-weight: 700;
      font-size: 20px;
      display: flex;
      align-items: center;
      color: #000000;
      margin: 10px 0;
    }

    .basket_wrapper {
      position: relative;
      max-width: 314px;
      width: 100%;
      // height: 63px;
      display: flex;
      margin-bottom: 20px;

      .close_button {
        position: absolute;
        cursor: pointer;
        right: 0;
      }

      .product_img {
        width: 91px;
        height: 83px;
        border: 1px solid #EBEBEB;
        border-radius: 8px;
        margin-right: 10px;
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: left;

        p {
          width: 175px;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color: #000000;
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        span {
          font-weight: 400;
          font-size: 20px;
          letter-spacing: 0.02em;
          color: #1E1D1D;

        }
      }
    }
  }

  .DeliverySubtotal {
    padding: 16px 35px;
    border-bottom: 1px solid #EDEDED;

    .Subtotal,
    .Delivery {
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      color: #7C7C7C;
      line-height: 23px;

      span {
        font-size: 18px;
        color: $black;
        line-height: 23px;
      }
    }
  }


  .YouPay {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    color: $black;
    font-weight: 600;
    line-height: 23px;
    padding: 0 18px;
  }

  .CheckOut {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;


    .CheckOutButton {
      width: 100%;
      background: $red;
      border-radius: 8px;
      text-align: center;
      font-size: 20px;
      color: #FFFFFF;
      font-weight: 700;
      -webkit-transition: 0.3s all;
      -moz-transition: 0.3s all;
      -ms-transition: 0.3s all;
      -o-transition: 0.3s all;
      transition: 0.3s all;
      border: 1px solid $red;
      width: 150px;
      height: 48px;
      background: #ED3237;
      border-radius: 10px;
      color: #FFFFFF;

      &:hover {
        background-color: #fff;
        color: $red;
      }
    }


    .AddItemButton {
      -webkit-transition: 0.3s all;
      -moz-transition: 0.3s all;
      -ms-transition: 0.3s all;
      -o-transition: 0.3s all;
      transition: 0.3s all;
      margin-bottom: 15px;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      width: 150px;
      height: 48px;
      background: #F58220;
      border-radius: 10px;
      color: #FFFFFF;

      &:hover {
        color: #F58634;
        background: #fff;
        border-color: #F58634;
      }
    }

    &.Empty {
      font-weight: 700;
    }
  }


  @media screen and (max-width: 767px) {
    max-width: calc(100vw - 16px);
    position: relative;
    top: 100px;
    margin: 8px auto;
    left: 0px;
    margin-bottom: 24px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;

    //left: 50%;
    ul {
      li {
        .FoodPrice {
          span {
            padding-left: 15px;
            flex-shrink: 0;
            flex-grow: 0;
          }
        }
      }
    }
  }
}

.BasketTitle {
  padding: 35px;
  margin-bottom: 0;
  font-size: 18px;
  color: $black;
  line-height: 23px;
  padding-bottom: 30px;
  border-bottom: 1px solid #EDEDED;
}


/* ================================
            animation
==================================*/
@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(-10px);
  }

  20% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-5px);
  }

  40% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-2px);
  }

  60% {
    transform: translateY(0);
  }

  70% {
    transform: translateY(0);
  }

  80% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }

}

@keyframes bounceX {
  0% {
    transform: translateX(0);
  }

  10% {
    transform: translateX(10px);
  }

  20% {
    transform: translateX(0);
  }

  30% {
    transform: translateX(5px);
  }

  40% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(2px);
  }

  60% {
    transform: translateX(0);
  }

  70% {
    transform: translateX(0);
  }

  80% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(0);
  }

}

.register_for_mobile {
  #registration_form {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ant-row {
      width: 350px;
      margin: 0 auto;
    }
  }
}

.Register {
  width: 800px !important;
}

.SignIn {
  .ant-modal-content {
    border-radius: 16px;
  }

  .SignInTitle {
    font-size: 30px;
    color: $black;
    line-height: 41px;
    font-weight: 700;
    margin-bottom: 55px;
    text-align: center;
  }

  .ant-modal-body {
    padding-bottom: 28px;
    padding-left: 40px;
    padding-right: 40px;
  }


  .login-form {
    label {
      font-size: 14px !important;
      color: #2E2E2E !important;
      font-weight: 600;
      line-height: 18px;
      margin-bottom: 13px;

      &::after,
      &::before {
        content: none;
      }
    }

    .ant-form-item-label {
      line-height: 18px;
    }

    .ant-col {
      max-width: 350px;
      width: 98%;
    }

    .ant-col.ant-form-item-control {
      margin-bottom: 18px;
    }

    input {
      border: 1px solid #DDDDDD;
      border-radius: 12px;
      /**************/
      height: 56px;
    }

    .LoginForgot {
      text-align: center;

      a {
        margin-bottom: 55px;
        font-size: 14px;
        display: inline-block;
        color: #959595;
        line-height: 18px;
        text-decoration: underline;

        &:hover {
          color: $black;
        }
      }
    }

    .login-form-button {
      width: 350px;
      background-color: #ED3237;
      background: $red;
      border: 1px solid $red;
      border-radius: 32px;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      font-weight: 600;
      display: block;
      margin: 0 auto;
      padding-top: 14px;
      padding-bottom: 14px;
      height: auto;
      -webkit-transition: 0.3s all;
      -moz-transition: 0.3s all;
      -ms-transition: 0.3s all;
      -o-transition: 0.3s all;
      transition: 0.3s all;
      margin-bottom: 30px;

      &:hover {
        background-color: #fff;
        color: $red;
      }
    }

    .DontHaveAccount {
      font-size: 14px;
      color: #7C7C7C;
      letter-spacing: 0;
      text-align: center;
      line-height: 18px;
      margin-top: 16px;
      margin-bottom: 0;

      a {
        font-weight: 700;
        color: $orange;
      }
    }
  }
}


.forMobile {
  width: 350px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: unset !important;
  box-shadow: unset !important;
  margin: 0 auto;
  margin-top: 100px;
}

.forgot_password {
  width: 584px;
  height: 400px;
  background: #FFFFFF;
  border: 1px solid rgba(102, 102, 102, 0.25);
  box-shadow: 0px 1px 5px 5px rgba(0, 0, 0, 0.12);
  border-radius: 16px;

  .ant-modal-content {
    border-radius: 16px;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .forgot_password_desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    letter-spacing: 0.4px;

    color: #292E34;

  }

}

#forgot_password_request {
  .ant-form-item-label {
    width: 100%;
  }

  .login-form {
    width: 100% !important;

  }

  .ant-form-item {
    display: flex !important;
    flex-direction: column !important;

    .ant-form-item-label {
      text-align: left;
    }

    .ant-form-item-control {
      width: 100% !important;
    }

    #forgot_password_request_email {
      width: 100%;
    }
  }
}

.LocationAdded {
  padding-top: 76px;

  @media screen and (max-width: 1200px) {
    padding-top: 60px;
  }
}

.LandingLeftMenu::-webkit-scrollbar {
  width: 4px;
}

.LandingLeftMenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.LandingLeftMenu::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
  outline: 1px solid #708090;
}

/* left menu */
.LandingLeftMenu {
  position: sticky;
  border: 1px solid #E7EAED;
  border-radius: 4px;
  top: 70px;
  min-width: 250px;
  padding: 20px 15px;
  max-width: 260px;
  margin-top: 35px;


  .restaurantType {
    button {
      display: block;
      margin: 0 auto 5px;

    }
  }

  .AddressAdded {
    position: relative;
    margin-top: 24px;
    padding-right: 17px;
    cursor: pointer;

    p {
      font-size: 14px;
      max-width: 170px;
      color: #1F1F1F;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    svg {
      width: 23px;
      height: auto;
      -webkit-transform: translateY(-1px);
      -moz-transform: translateY(-1px);
      -ms-transform: translateY(-1px);
      -o-transform: translateY(-1px);
      transform: translateY(-1px);

      g {
        path {
          fill: #BCC3CC;
        }
      }
    }
  }

  .feature {
    p {
      font-size: 14px;
      color: #1B1B1B;
      line-height: 18px;
      margin-bottom: 6px;
    }

    span.clear {
      font-weight: 400;
      font-size: 16px;
      text-decoration: underline;
      cursor: pointer;
    }

    span:not(.clear) {
      color: #212121;
    }


  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #212121;
    border-color: #212121;
  }

  .ant-checkbox-checked:after {
    border-color: #212121;
  }

  .ant-checkbox-input:focus+.ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #212121;
  }


  .ant-radio-checked .ant-radio-inner {
    background-color: #212121;
    border-color: #212121;
  }

  .ant-radio-checked:after {
    border-color: #212121;
  }

  .ant-slider:hover .ant-slider-track {
    background-color: black;
  }

  .ant-slider-track {
    background-color: black;
  }

  .ant-radio-input:focus+.ant-radio-inner,
  .ant-radio-wrapper:hover .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner {
    border-color: #212121;
  }

  h5 {
    font-size: 18px;
    color: #1B1B1B;
    font-weight: 600;
    line-height: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 30px;
  }

  .MenuS {
    margin-top: 20px;

    .catScroll {
      max-height: 330px;
      overflow: auto;
      padding-left: 15px;
    }

    .catScroll::-webkit-scrollbar {
      width: 4px;
    }

    .catScroll::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }

    .catScroll::-webkit-scrollbar-thumb {
      background-color: #a9a9a9;
      outline: 1px solid #708090;
    }


    .CategoryUl {
      padding: 14px 18px 14px 0px;
      background: #F3F3F3;
      border-radius: 8px;


      .ant-checkbox-group {
        width: 100%;
      }

      .ant-checkbox {
        display: none;

        &+span {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 4px;
          align-items: center;
          font-size: 14px;
          color: #1B1B1B;
          border: none;
          background-color: transparent;
          line-height: 18px;

          &:hover {
            color: $red !important;

            -webkit-transition: 0.15s all;
            -moz-transition: 0.15s all;
            -ms-transition: 0.15s all;
            -o-transition: 0.15s all;
            transition: 0.15s all;
          }
        }
      }

      .ant-checkbox-wrapper {
        display: block;
        margin-left: 0;

        &.ant-checkbox-wrapper-checked {
          position: relative;

          &::after {
            content: '';
            //content: url("../../img/check-mark.png");
            width: 14px;
            height: 14px;
            position: absolute;
            left: -8px;
            top: 50%;
            background-image: url("../../img/check-mark.png");
            -webkit-background-size: 10px;
            background-size: 8px;
            background-repeat: no-repeat;
            background-position: center center;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
          }
        }
      }
    }

  }

  .filter_item {
    .filter_header_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      img {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);

        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;

        -webkit-transition: 300ms ease all;
        -moz-transition: 300ms ease all;
        -o-transition: 300ms ease all;
        transition: 300ms ease all;
      }

      .imgRotate {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);

        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
      }
    }

    .filter_body_wrapper {
      height: auto;
    }

    .withHeight {
      height: 0;
      transition: 300ms ease all;
      display: none;
    }
  }

  .Price {
    margin-top: 8px;
    padding-top: 15px;
    border-top: 1px solid #E7EAED;

    .pricesInputs {
      display: flex;
      justify-content: space-between;
      align-items: center;

      input {
        width: 40%;
        background: #FFFFFF;
        border: 1px solid #E7EAED;
        border-radius: 4px;
        padding: 10px;
      }
    }

    >div {
      display: flex;
      align-items: center;
      flex-direction: column;

      .ant-slider {
        width: 97%;
      }
    }

    span {
      font-size: 18px;
      color: $black;
      line-height: 23px;
    }

    .ant-radio-group.ant-radio-group-solid {
      background: #F3F3F3;
      border-radius: 6px;
      border: 2px solid #F3F3F3;
      padding: 2px;

    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      content: none;
    }

    .ant-radio-button-wrapper {
      background-color: transparent;
      border: none;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      padding: 0 14px;

      &:focus {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }

    .ant-radio-button-wrapper-checked {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      background-color: #fff;
    }

    .ant-radio-button-wrapper:hover {
      color: #ED3237;
    }

    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: #fff;
      color: #ED3237;
      border-color: #fff;

    }

    .ant-slider-handle {
      border: none;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    }
  }


  .Categories {
    padding-top: 18px;

    h5 {
      margin-bottom: 10px;
    }
  }

  .SearchButton {
    margin-top: 40px;
    border-radius: 8px;
    font-size: 14px;
    background: $red;
    color: #FFFFFF;
    border: 1px solid $red;
    font-weight: 600;
    text-align: center;
    display: block;
    margin-left: auto;
    padding: 8px;
    width: 100%;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -o-transition: 0.3s all;
    transition: 0.3s all;

    &:hover {
      background-color: #fff;
      color: $red;
    }
  }

  @media screen and (max-width: 635px) {


    &.open {
      display: block;
      height: 100%;
      overflow: auto;
      padding-bottom: 80px;
      padding-top: 35px;
      //display: none;
      position: fixed;
      top: 40px;
      left: 0;
      width: 100%;
      background-color: #fff;
      z-index: 4;
    }
  }
}


/*===================================
  map
===================================*/

.ModalMap {
  position: relative;
  height: 506px;
  background-color: rgba(0, 0, 0, 0.15);
  width: calc(100% - 24px);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.MapModalLocation {
  font-size: 16px;
  color: #7C7C7C;
  margin-bottom: 0;
  margin-top: 24px;
  line-height: 20px;
}

// select image
.item-img {
  width: 30px !important;
  margin-right: 5px;
  vertical-align: center;
}

/*===================================
        global input
===================================*/

.GlobalInput {
  p {
    font-size: 12px;
    color: #2E2E2E;
    font-weight: 700;
    line-height: 15px;
    margin-bottom: 6px;
  }

  .req:after {
    content: "*" !important;
    color: #b62b2b;
    display: inline !important;
    margin-left: 3px;
  }

  input,
  textarea {
    margin-bottom: 16px;
    width: 100%;
    border: 1px solid #DDDDDD;
    border-radius: 6px;
    font-size: 12px;
    color: #2E2E2E;
    line-height: 15px;
    padding: 12px 15px;

    &::placeholder {
      color: #959595;
    }

    &:focus {
      outline: none;
    }
  }

  textarea {
    min-height: 70px;
    resize: none;
    margin-bottom: 27px;
  }
}

.dis-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 35px;
}


.ant-dropdown-menu {
  .ant-dropdown-menu-item {
    a {
      text-align: center;
      font-size: 14px;
      font-weight: 600;
    }
  }

  & :last-child {
    a {
      color: #ED3237;
    }
  }
}

.pageNotFound {
  min-width: 350px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 20%;
  height: 100%;
  margin: 150px auto;
  align-items: center;

  .input_wrapper {
    position: relative;

    img {
      position: absolute;
      left: 8px;
      top: 15px;
    }

    input {
      width: 360px;
      height: 52px;
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid #E7EAED;
      border-radius: 10px;
      outline: none;
      padding: 0 35px;
      font-size: 20px;

      &::placeholder {
        font-size: 20px;
        margin-left: 50px;
      }
    }

  }

  button {
    width: 216px;
    height: 44px;
    background: #F58220;
    border: 1px solid #F58220;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #FFFFFF;
    margin-top: 20px;

  }
}

#registration_form {
  width: 100%;
  // width: fit-content;

  .ant-row {
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
  }

  button {
    margin-top: 25px;
  }
}

.pointer {
  cursor: pointer;
}

.getFoodButton {

  flex-direction: column;
  margin-top: 50px;
  align-items: flex-start;
  justify-content: start !important;

  span {
    font-weight: bold;
  }

  p {
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
  }
}

.mobile_uList {
  display: flex;
  width: 303px;
  height: 100vh;
  background: #000000;
  border-radius: 0px 10px 10px 0px;
  position: absolute;
  left: 0;
  top: 0;
  flex-direction: column;
  z-index: 9999;

  .mobile_list_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 10px;

  }

  .sign_menu {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    margin-bottom: 40px;
  }

  .mobile_ul_list_items {
    margin-top: 10px;
    display: flex;
    height: 60%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 10px;

    li {
      -webkit-user-select: none;
      -webkit-touch-callout: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.005em;
      color: #FFFFFF;
      margin-top: 30px;
    }
  }

  .sign_menu {
    display: flex;

    .login_button {
      border: none;
      width: 145px;
      height: 39px;
      background: #ED3237;
      border-radius: 6px;
      font-size: 20px;
      line-height: 25px;
      letter-spacing: 0.005em;
      color: #FFFFFF;
    }

    .sigh_up_button {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      display: flex;
      align-items: center;
      letter-spacing: 0.005em;
      background: transparent;
      color: #FFFFFF;
      border: none;
    }
  }
}

.catering_menu {
  background-size: 800px;
  background-position: 630px 0;
}

@media screen and (max-width: 1200px) {

  .catering_menu {
    background-size: 300px;
    background-position: 139px 264px;
  }

  header {
    background-size: 200px;
    background-position: 100% 310px;

    .get_food_type_wrapper {
      .get_food_type_desc {
        margin: 30px 10px;
        width: 50%;
      }
    }
  }

}


.SignInTitle {
  .ant-form-item {
    max-width: 350px !important;
    width: 98% !important;
    margin: 0 auto !important;
  }
}

.vistable_div {
  width: 430px;
  height: 50px;
  background-color: transparent;
}

.modal_footer {
  width: 584px;
  height: 99px;
  background: #FFFFFF;
  border: 1px solid rgba(102, 102, 102, 0.25);
  box-shadow: 0px 1px 5px 5px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
}


.modal_fon {
  width: 100vw;
  overflow: hidden !important;
  height: calc(100vh + 170px);
  background: #000;
  opacity: 0.75;
  position: absolute;
  top: -170px;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
}

.error_boundary {
  display: flex;
  min-width: 350px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 40%;
  height: 100%;
  margin: 150px auto;
  align-items: center;

  p {
    font-weight: 700;
    font-size: 32px;
    line-height: 160%;
    text-align: center;
    color: #000000;
  }

  h1 {
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    color: #313030;
  }

  button {
    width: 216px;
    height: 44px;
    background: #F58220;
    border: 1px solid #F58220;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #FFFFFF;
    margin-top: 20px;

  }
}

.Location_Modal {
  transform-origin: unset;
  height: 297px;
  padding: 25px;
  width: 800px !important;

  >div:not(.getFoodType) {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .ant-modal-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    margin-right: unset;
  }

  .ant-modal-body {
    width: 100%;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 5px;
    color: #333333;
  }

  h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #292E34;

  }

  .getFoodType {
    box-shadow: none;
    padding: 0;
    max-width: unset;
    width: 100%;
    min-width: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 !important;

    .getFoodButton {
      span {
        left: 30px;
      }
    }
  }

}


@media (max-width: 730px) {
  .Location_Modal {
    width: 95% !important;

    h2 {
      font-size: 22px;
    }

    h5 {
      width: 70%;
      font-size: 18px;
    }

    .getFoodButton {
      flex-direction: column;

      span {
        top: 10px;
      }

      button {
        padding: none;
        margin-top: 10px;
      }
    }


  }
}

@media (max-width: 374px) {
  .getFoodButton {
    flex-direction: column !important;
    width: 90% !important;
    justify-content: center !important;
    align-items: center;

    span {
      top: 10px;
      background: #F5F5F5;
    }

    .vehicleInformation {
      width: 95% !important;
    }

    button {
      margin-top: 10px;
    }
  }
}

.ant-spin-nested-loading {
  width: 100%;
  // max-width: 1400px;
}

.hideScrollBar {
  &::-webkit-scrollbar {
    width: 0;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    width: 0;
    background: #ffffff;
    border-radius: 1.5px;
  }
}