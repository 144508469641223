.wrapper_button {
    // width: 235px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    span {
        font-size: 20px;
        color: #E7792B;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
    }

    p {
        width: 180px;
        font-size: 32px;
        color: #5D5D5D;
        letter-spacing: -0.01em;
        line-height: 40px;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 700;
    }

    .wrapper_btn {
        width: 182px;
        height: 60px;
        background: #ED3237;
        border-radius: 8px;
        border: 0;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 17px;
        line-height: 100%;
    }

    @media screen and (max-width:1200px) {
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        p {
            width: 100%;
            text-align: center;
            margin-bottom: 0;
            font-size: 16px;
            line-height: 25px;
            margin-right: 20px;
        }

        span {
            display: none;
        }

        .wrapper_btn {
            width: 139px;
            height: 30px;
        }
    }
}